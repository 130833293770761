/*
---------------------------------------
Transition
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin transition-base {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

@mixin transition-opacity {
  @include transition-base;
  transition-property: opacity;
}

@mixin transition-works {
  @include transition-base;
  transition-property: transform;
}
