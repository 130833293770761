/*
---------------------------------------
Text
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/global/mixins/font-families" as *;
@use "scss/global/mixins/media-queries" as *;

// SP:18px, PC:24px
@mixin text-18-24 {
  font-size: px2rem(18);

  @include sm {
    font-size: px2rem(24);
  }
}

// SP:14px, PC:14px
@mixin text-14 {
  font-size: px2rem(14);
}
