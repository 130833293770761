/*
---------------------------------------
Spaces
---------------------------------------
*/

@use "scss/global/functions" as *;
@use "scss/global/variables" as *;
@use "scss/global/mixins/media-queries" as *;

//// Margin

// Top margin with the Section.
@mixin space-section-top {
  margin-top: $section-top;

  @include sm {
    margin-top: $section-top-sm;
  }
}

// Top margin of the content within the Section.
@mixin space-content-top {
  margin-top: $content-top;

  @include sm {
    margin-top: $content-top-sm;
  }
}

// Top margin of the item.
@mixin space-item-top {
  margin-top: $item-top;

  @include sm {
    margin-top: $item-top-sm;
  }
}

// ページの最初のsectionまたはコンテンツの余白
@mixin space-page-first-section {
  padding-top: $page-first-section-top;
  padding-bottom: $page-first-section-bottom;

  @include sm {
    padding-bottom: $page-first-section-bottom-sm;
  }
}

/// Padding

// Right and left padding of the content.
@mixin space-in-content-x {
  padding-right: $in-content-x;
  padding-left: $in-content-x;

  @include sm {
    padding-right: $in-content-x-sm;
    padding-left: $in-content-x-sm;
  }
}

// Top and bottom padding of the content.
@mixin space-in-content-y {
  padding-top: $in-content-y;
  padding-bottom: $in-content-y;

  @include sm {
    padding-top: $in-content-y-sm;
    padding-bottom: $in-content-y-sm;
  }
}

// Padding on all sides of the content.
@mixin space-in-content {
  @include space-in-content-x;
  @include space-in-content-y;
}

/// Heading

@mixin space-heading-26-46-top {
  margin-top: $heading-26-46-top;

  @include sm {
    margin-top: $heading-26-46-top-sm;
  }
}

@mixin space-heading-18-top {
  margin-top: $heading-18-top;

  @include sm {
    margin-top: $heading-18-top-sm;
  }
}
