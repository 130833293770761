/*
---------------------------------------
Buttons
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// standard
@mixin button-standard {
  @include transition-opacity;

  width: 100%;
  padding: px2rem(15);
  color: $white;
  background: $gray;
  border: 1px solid $gray;
}

// rounded
@mixin button-rounded {
  @include transition-opacity;

  display: inline-block;
  padding: 0 px2rem(20);
  font-size: px2rem(12);
  border-radius: 18px;

  @include sm {
    font-size: px2rem(14);
  }

  &::before {
    display: inline-block;
    height: 36px;
    vertical-align: middle;
    content: "";
  }
}

// dark-gray
@mixin button-color-dark-gray {
  color: $white;
  background: $gray;
  border-color: $gray;
}

// light-gray
@mixin button-color-light-gray {
  color: $gray;
  background: $gray-100;
}

// white
@mixin button-color-white {
  color: $gray;
  background: $white;
}

// arrow
@mixin button-arrow {
  display: inline-block;
  font-size: px2rem(16);
  color: $black;

  @include sm {
    font-size: px2rem(14);
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    content: " >";
  }

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
}
