/*
---------------------------------------
Layouts
---------------------------------------
*/

@use "scss/global/mixins/media-queries" as *;
@use "scss/global/mixins/spaces" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// 画面フルサイズ
@mixin layout-full {
  width: 100%;
}

// 画面フルサイズで余白あり
@mixin layout-inner-full {
  width: min($inner-width, $inner-width-full);
  margin-inline: auto;
}

// 基本のコンテンツ幅
@mixin layout-inner {
  width: min($inner-width, $inner-width-base);
  margin-inline: auto;

  @include sm {
    width: min($inner-width-sm, $inner-width-base);
  }
}

// 基本のコンテンツ幅より狭い幅
@mixin layout-inner-sm {
  width: min($inner-width, $inner-width-small);
  margin-inline: auto;
}

// grid
@mixin layout-grid($gap: px2rem(32)) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(px2rem(220), 1fr));
  gap: $gap;
}

// flexを使用したカラムのコンテンツが1行
@mixin layout-flex($gap: px2rem(32)) {
  display: flex;
  flex-direction: column;
  gap: $gap;

  @include sm {
    flex-direction: row;
    gap: $gap;
  }
}

// flexを使用したカラムのコンテンツが複数行
@mixin layout-flex-multiple-line {
  @include layout-flex-column;
  flex-wrap: wrap;
}

// flexを使用したカラムのコンテンツ幅
@mixin layout-flex-column($columns-number: 2, $gap: px2rem(32)) {
  width: 100%;

  @include sm {
    width: calc((100% - ($gap * ($columns-number - 1))) / $columns-number);
  }
}

// コンテンツの幅に合わせたサイズをセンター揃え
@mixin layout-fit-content {
  width: fit-content;
  margin-inline: auto;
}

// PC時に2カラムでSP時にフルサイズ、PC時には個別サイズ
@mixin layout-column-two-full {
  display: flex;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }
}

// PC時に2カラムで画像がSP時にフルサイズ、PC時にはカラムのフルサイズ
@mixin lauyout-column-two-image {
  width: 100%;
  height: min(240px, 30%);

  @include sm {
    width: 60%;
    height: 480px;
  }
}

// PC時に2カラムで文章のコンテンツがSP時は左右に余白、PC時にはカラムのフルサイズ
@mixin layout-column-two-article {
  @include space-in-content-x;
  padding-top: $in-item-y;
  padding-bottom: $in-item-y;

  @include sm {
    width: 40%;
    padding-top: 0;
  }
}
