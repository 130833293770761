/*
---------------------------------------
swiper
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

.c-swiper {
  &.swiper {
    width: 100%;
    height: calc(100vh - $header-height);

    @include sm {
      height: calc(100vh - $header-height-sm);
    }
  }

  // slide-image
  &__slide-image {
    @include image-object-fit;
  }

  // button
  &__button {
    &.swiper-button-prev,
    &.swiper-button-next {
      color: $white;
    }

    &::after {
      display: none;
    }
  }

  // pagination
  &__pagination {
    &.swiper-pagination {
      color: $gray-100;
    }

    .swiper-pagination-bullet {
      opacity: 0.4;

      &.swiper-pagination-bullet-active {
        background-color: $gray-100;
        opacity: 1;
      }
    }
  }
}
