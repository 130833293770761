/*
---------------------------------------
index
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

[id="works-show"] {
  .pg-works-show {
    @include space-content-top;
    @include layout-inner;

    // container
    &__container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(px2rem(256), 1fr));
      width: 100%;
    }

    // images-wapper
    &__images-wrapper {
      grid-column: 1 / 3;
      padding: $in-content-y $in-content-x 0;

      @include sm {
        padding-top: $in-content-y-sm;
      }
    }

    // image
    &__image {
      @include space-content-top;
      width: 100%;

      @include lg {
        max-width: px2rem(800);
        margin-right: auto;
        margin-left: auto;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    // image-caption
    &__image-caption {
      margin-top: px2rem(5);
      font-size: px2rem(12);
    }

    // body
    &__body {
      padding-bottom: $content-top / 2;

      @include lg {
        padding-bottom: $content-top-sm / 2;
      }
    }

    // information
    &__information {
      grid-column: 1 / 3;
      padding: $in-content-y $in-content-x 0;

      @include sm {
        padding-top: $in-content-y-sm;
      }

      @include md {
        position: sticky;
        top: 0;
        left: 0;
        grid-column: auto;
        height: 100vh;
        overflow-y: scroll;
      }

      @include lg {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
        gap: $item-top-sm;
        align-content: start;
        padding: (px2rem(40) * 2) px2rem(45) px2rem(30);
      }
    }

    // information-item
    &__information-item {
      margin-top: $item-top;

      @include lg {
        margin-top: 0;
      }

      &:first-child {
        @include space-content-top;
      }
    }

    // information-item-heading
    &__information-item-heading {
      font-weight: $font-bold;
      color: $black;
    }

    // information-item-text
    &__information-item-text {
      color: $gray;
    }

    // tag
    &__tag {
      display: inline-block;
      font-size: px2rem(11);
      color: $gray-200;

      &::after {
        display: inline-block;
        margin-right: px2rem(4);
        content: ",";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    // client-item
    &__client-item {
      margin-top: $item-top / 2;
      font-size: px2rem(12);
    }

    // client-item-title
    &__client-item-title {
      display: inline-block;
      font-weight: $font-normal;

      &::after {
        display: inline-block;
        margin-right: px2rem(2);
        content: ": ";
      }
    }

    // client-item-data
    &__client-item-data {
      display: inline-block;

      // instagram
      &--instagram {
        &::before {
          display: inline;
          content: "@";
        }
      }
    }

    // roles
    &__roles {
      margin-top: $item-top / 2;
      font-size: px2rem(12);
    }

    // roles-role
    &__roles-role {
      display: inline-block;
      font-weight: $font-normal;

      &::after {
        display: inline-block;
        margin-right: px2rem(2);
        content: ": ";
      }
    }

    // roles-name
    &__roles-name {
      display: inline-block;
    }

    // button
    &__button {
      @include space-content-top;
    }
  }
}
