/*
---------------------------------------
Form Items
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// focus
@mixin this-focus-base-style {
  border-color: $gray;
  outline: none;
  box-shadow: 0 0 0 2px rgba(red($gray), green($gray), blue($gray), 0.5);
}

// Form Base Style
@mixin this-form-base-style {
  display: block;
  width: 100%;
  padding: px2rem(7);
  font-size: px2rem(14);
  line-height: $leading-tight;
  border: 1px solid $gray;
  border-radius: px2rem(3);

  // focus
  &:focus {
    @include this-focus-base-style;
  }

  // is-invalid
  &.is-invalid {
    border-color: $red;
  }
}

// placeholder
@mixin form-item-placeholder($color: $gray) {
  ::placeholder {
    color: $color;
  }
}

// input
@mixin form-item-input {
  @include this-form-base-style;
  width: 100%;
}

// textarea
@mixin form-item-textarea {
  @include this-form-base-style;
  width: 100%;
}

// radio-list
@mixin form-item-radio-list {
  display: flex;
  flex-wrap: wrap;
  gap: px2rem(30);

  @include sm {
    gap: px2rem(12) px2rem(20);
  }
}

// radio
@mixin form-item-radio {
  display: flex;
  gap: px2rem(6);
  align-items: center;

  [type="radio"] {
    // focus
    &:focus {
      @include this-focus-base-style;
    }

    &:checked {
      background-color: $gray;
    }
  }

  // is-invalid
  &.is-invalid {
    input {
      border-color: $red;
    }

    label {
      color: $red;
    }
  }
}

// checkbox-list
@mixin form-item-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  gap: px2rem(24);

  @include sm {
    gap: px2rem(12) px2rem(20);
  }
}

// checkbox
@mixin form-item-checkbox {
  display: flex;
  gap: px2rem(6);
  align-items: center;

  [type="checkbox"] {
    border-radius: px2rem(3);

    // focus
    &:focus {
      @include this-focus-base-style;
    }

    &:checked {
      background-color: $gray;
    }
  }

  // is-invalid
  &.is-invalid {
    input {
      border-color: $red;
    }

    label {
      color: $red;
    }
  }
}

// select
@mixin form-item-select {
  @include this-form-base-style;
  width: 100%;
}

// required
@mixin form-item-required {
  &::after {
    display: inline-block;
    color: $red;
    content: "※";
  }
}

// notes
@mixin form-item-notes {
  font-size: px2rem(10);
}

// helper text
@mixin form-item-helper-text {
  font-size: px2rem(10);
}

// error
@mixin form-item-error {
  display: block;
  font-size: px2rem(12);
  color: $red;
}
