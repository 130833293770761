/*
---------------------------------------
icons
---------------------------------------
*/
@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("files/icomoon.eot?p19nl5");
  src: url("files/icomoon.eot?p19nl5#iefix") format("embedded-opentype"), url("files/icomoon.ttf?p19nl5") format("truetype"), url("files/icomoon.woff?p19nl5") format("woff"),
    url("files/icomoon.svg?p19nl5#icomoon") format("svg");
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook::before {
  content: "\ea90";
}

.icon-instagram::before {
  content: "\ea92";
}

.icon-twitter::before {
  content: "\ea96";
}
