/*
---------------------------------------
Heading Text Section
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

.c-heading-text-section {
  // heading
  &__heading {
    @include heading-18;
  }

  // text
  &__text {
    @include text-14;
    @include space-heading-18-top;
  }
}
