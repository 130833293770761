/*
---------------------------------------
Global Navigation
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// Hamburger Menu Animation

@include keyframes(naviLineFirstOpen) {
  //上下バーから中央バーまでの距離をtranslateYで指定
  49% {
    opacity: 1;
    transform: translate(0, 6px);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(0, 6px);
  }
}

@include keyframes(naviLineFirstClose) {
  0% {
    opacity: 0;
    transform: translate(0, 6px);
  }

  50% {
    opacity: 0;
    transform: translate(0, 6px);
  }

  51% {
    opacity: 1;
  }

  100% {
    transform: translate(0);
  }
}

@include keyframes(naviLineLastOpen) {
  49% {
    opacity: 1;
    transform: translate(0, -6px);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(0, -6px);
  }
}

@include keyframes(naviLineLastClose) {
  0% {
    opacity: 0;
    transform: translate(0, -$hamburger-animation-translate-y);
  }

  50% {
    opacity: 0;
    transform: translate(0, -$hamburger-animation-translate-y);
  }

  51% {
    opacity: 1;
  }

  100% {
    transform: translate(0);
  }
}

@include keyframes(naviLineSecondOpen) {
  50% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(45deg);
  }
}

@include keyframes(naviLineSecondClose) {
  0% {
    transform: rotateZ(45deg);
  }

  50% {
    transform: rotateZ(0);
  }
}

@include keyframes(naviLineSecondAfterOpen) {
  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: rotateZ(0);
  }

  100% {
    opacity: 1;
    transform: rotateZ(-90deg);
  }
}

@include keyframes(naviLineSecondAfterClose) {
  0% {
    opacity: 1;
    transform: rotateZ(-90deg);
  }

  50% {
    opacity: 1;
    transform: rotateZ(0);
  }

  51% {
    opacity: 0;
  }
}

[id="global-navigation"] {
  .global-navigation {
    width: 100vw;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    background: $gray;
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    transition-property: height, overflow, padding-bottom, visibility;

    @include sm {
      width: auto;
      height: auto;
      visibility: visible;
      background: transparent;
      transition: none;
    }

    &.is-active {
      height: 100vh;
      padding-bottom: px2rem(175);
      overflow-y: scroll;
      visibility: visible;
      transition-timing-function: ease-in-out;
      transition-duration: 0.5s;
      transition-property: height, overflow, padding-bottom, visibility;
      -webkit-overflow-scrolling: touch;
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: px2rem(60) 8%;
      overflow: hidden scroll;
      scrollbar-width: 0;

      @include sm {
        flex-direction: row;
        gap: px2rem(30);
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      @include sm {
        display: flex;
        align-items: center;
      }

      &:first-child {
        border-top: 1px solid #616161;

        @include sm {
          border-top: 0;
        }
      }

      // icon
      &--icon {
        display: none;

        @include sm {
          display: flex;
        }
      }
    }

    &__link {
      display: inline-block;
      width: 100%;
      padding: px2rem(10) 0;
      color: $white;
      border-bottom: 1px solid #616161;

      @include sm {
        width: auto;
        padding: 0;
        font-size: px2rem(12);
        font-weight: $font-bold;
        color: $black;
        border-bottom: 0;
      }

      // icon
      &--icon {
        align-self: flex-start;
        width: px2rem(20);
      }
    }

    // link-icon
    &__link-icon {
      width: 100%;
    }
  }
}

.l-button__hamburger {
  position: relative;
  z-index: 100;
  width: $navi_button_width;
  cursor: pointer;

  @include sm {
    display: none;
  }

  &--bar {
    width: 100%;
    height: $hamburger-line-height;
    background: $hamburger-line-color;

    &:first-child {
      @include animation(naviLineFirstClose);
    }

    &:nth-child(2) {
      @include animation(naviLineSecondClose);

      position: relative;
      margin: $hamburger-second-line-margin-top-bottom 0;

      &::after {
        @include animation(naviLineSecondAfterClose);

        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: $hamburger-line-height;
        content: "";
        background: $hamburger-line-color;
        opacity: 0;
      }
    }

    &:last-child {
      @include animation(naviLineLastClose);
    }
  }

  // ページ読み込み時のアニメーションを停止
  &.js-closed {
    .l-button__hamburger--bar:first-child {
      animation: none;
    }

    .l-button__hamburger--bar:nth-child(2) {
      animation: none;

      &::after {
        animation: none;
      }
    }

    .l-button__hamburger--bar:last-child {
      animation: none;
    }
  }

  // メニューを開く時のアニメーション
  &.is-active {
    .l-button__hamburger--bar:first-child {
      @include animation(naviLineFirstOpen);
    }

    .l-button__hamburger--bar:nth-child(2) {
      @include animation(naviLineSecondOpen);

      &::after {
        @include animation(naviLineSecondAfterOpen);
      }
    }

    .l-button__hamburger--bar:last-child {
      @include animation(naviLineLastOpen);
    }
  }
}
