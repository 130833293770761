/*
---------------------------------------
Images
---------------------------------------
*/

@mixin image-object-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
