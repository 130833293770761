/*
---------------------------------------
Font Family
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin font-futura {
  font-family: "futura-pt", sans-serif;
}

@mixin font-noto-sans-jp {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin font-lato {
  font-family: "Lato", sans-serif;
}
