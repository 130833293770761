/*
---------------------------------------
index
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

[id="index"] {
  // cover
  .pg-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: $gray-100;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10rem;

    // heading
    &__heading {
      @include font-futura;

      font-size: px2rem(14);
      color: $gray;
      text-align: center;
    }

    // hello
    &__hello {
      font-size: px2rem(30);
      letter-spacing: $tracking-wide;
    }

    // logo
    &__logo {
      display: block;
      width: clamp(100px, 15vw, 160px);
      margin: px2rem(60) auto 0;
    }

    // text
    &__text {
      @include space-in-content-x;

      max-width: px2rem(638);
      margin-top: px2rem(80);
      text-align: left;
    }
  }

  // works
  .pg-works {
    // inner
    &__inner {
      @include md {
        @include layout-full;
      }
    }

    // item
    &__item {
      display: flex;
      flex-direction: column-reverse;
      background-color: $gray-600;

      @include md {
        flex-direction: row;
        height: 100vh;
        min-height: px2rem(768);
      }
    }

    // info
    &__info {
      @include font-futura;
      @include space-in-content;

      position: relative;
      font-size: px2rem(12);
      font-weight: $font-medium;

      @include md {
        flex: 1 0 px2rem(326);
        font-size: px2rem(14);
      }

      @include lg {
        flex: 0 0 px2rem(440);
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba($gray-200, 0.5);
      }
    }

    // info-list
    &__info-list {
      position: relative;
      z-index: 10;
    }

    // work-info-list
    &__work-info-list {
      margin-top: px2rem(20);

      @include md {
        margin-top: px2rem(40);
      }
    }

    // work-info-title
    &__work-info-title {
      display: inline-block;
      font-weight: $font-medium;

      &::after {
        display: inline-block;
        margin-right: px2rem(2);
        content: ": ";
      }
    }

    // work-info-data
    &__work-info-data {
      display: inline-block;
    }

    // tag
    &__tag {
      @include space-item-top;
    }

    // tag-item
    &__tag-item {
      display: inline-block;

      &::after {
        display: inline-block;
        margin-right: px2rem(4);
        content: ",";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    // client-info
    &__client-info {
      margin-top: px2rem(20);

      @include md {
        margin-top: px2rem(80);
      }
    }

    // client-info-title
    &__client-info-title {
      display: inline-block;
      font-weight: $font-medium;

      &::after {
        display: inline-block;
        margin-right: px2rem(2);
        content: ": ";
      }
    }

    // client-info-data
    &__client-info-data {
      display: inline-block;

      // instagram
      &--instagram {
        &:hover {
          cursor: pointer;
        }

        &::before {
          display: inline;
          content: "@";
        }
      }
    }

    // image
    &__image {
      @include space-in-content;

      @include md {
        display: grid;
        flex: 1 0 px2rem(442);
        place-content: center;
        height: 100%;
      }
    }

    // image-img
    &__image-img {
      width: 100%;

      @include md {
        max-width: px2rem(800);
        max-height: calc(100vh - ($in-content-y * 2));
      }

      @include lg {
        max-width: px2rem(800);
      }
    }

    // button
    &__button {
      margin-top: px2rem(40);

      @include md {
        margin-top: px2rem(80);
      }
    }
  }

  // // concept
  // .pg-concept {
  //   // wrapper
  //   &__wrapper {
  //     @include layout-column-two-full;
  //   }

  //   // image
  //   &__image {
  //     @include lauyout-column-two-image;

  //     // img
  //     &-img {
  //       @include image-object-fit;
  //     }
  //   }

  //   // article
  //   &__article {
  //     @include layout-column-two-article;
  //   }

  //   // copy
  //   &__copy {
  //     @include heading-18;
  //   }

  //   // text
  //   &__text {
  //     @include text-14;
  //     @include space-heading-18-top;
  //   }

  //   // button
  //   &__button {
  //     @include space-content-top;
  //   }
  // }
}
