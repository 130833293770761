/*
---------------------------------------
Base
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

html {
  // web fontsの読み込み時の FOUT (Flash Of Unstyled Text) 対策(web-font-loaderでclass付与)
  opacity: 0;
}

.body {
  @include font-noto-sans-jp;
  min-width: $se;
  padding-top: $header-height;
  font-size: px2rem(14);
  font-weight: $font-normal;
  line-height: $leading-base;
  color: $black;
  letter-spacing: $tracking-base;

  @include sm {
    padding-top: $header-height-sm;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-bold;
}

a {
  @include transition-opacity;
  color: $black;
  text-decoration: none;

  &:hover {
    @include hover-opacity;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

li {
  list-style: none;
}

input,
textarea {
  appearance: none;
}
