/*
---------------------------------------
plants index
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

[id="plants"] {
  .c-page-title__heading {
    text-align: left;
  }

  // plants
  .pg-plants {
    @include space-in-content-y;

    display: grid;

    // page-title
    &__page-title {
      @include space-in-content-x;

      text-align: center;

      @include lg {
        padding-right: 0;
        padding-left: $in-content-x-sm;
        text-align: left;
      }
    }

    // howto
    &__howto {
      @include space-in-content-x;
      @include space-content-top;

      @include lg {
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 2;
        grid-column-end: 3;
        width: 100%;
        height: 100%;
        padding-right: px2rem(90);
        padding-left: px2rem(90);
        margin-top: 0;
      }
    }

    // howto-item
    &__howto-item {
      @include space-content-top;

      &:first-child {
        margin-top: 0;
      }
    }

    // howto-item-title
    &__howto-item-title {
      font-size: px2rem(16);
    }

    // howto-item-body
    &__howto-item-body {
      @include space-item-top;
    }

    // howto-item-body-content
    &__howto-item-body-content {
      @include space-item-top;

      &:first-child {
        margin-top: 0;
      }
    }

    // howto-item-body-heading
    &__howto-item-body-heading {
      font-weight: bold;
    }

    // howto-item-space-top
    &__howto-item-space-top {
      @include space-item-top;
    }

    // howto-item-notes
    &__howto-item-notes {
      font-size: px2rem(14);
      color: $gray-600;
    }

    // howto-item-flow
    &__howto-item-flow {
      @include space-item-top;

      // no-space-top
      &--no-space-top {
        margin-top: 0;
      }
    }

    // howto-item-flow-item
    &__howto-item-flow-item {
      list-style-position: inside;
      list-style-type: decimal;
    }

    // howto-item-list
    &__howto-item-list {
      @include space-item-top;

      // no-space-top
      &--no-space-top {
        margin-top: 0;
      }
    }

    // howto-item-list-item
    &__howto-item-list-item {
      list-style-position: inside;
      list-style-type: disc;
    }

    // howto-navi-list
    &__howto-navi-list {
      @include font-futura;
      @include space-in-content-x;

      @include lg {
        position: sticky;
        top: $content-top;
        max-width: px2rem(300);
        padding-right: 0;
        margin-top: $content-top;
        margin-top: $item-top-sm;
      }
    }

    // howto-navi-item
    &__howto-navi-item {
      display: block;
      margin-top: $item-top;

      @include lg {
        margin-top: $item-top-sm;
      }
    }

    // howto-navi-title
    &__howto-navi-title {
      @include font-lato;

      display: block;
      margin-top: $item-top;
      font-weight: $font-normal;

      @include lg {
        font-size: px2rem(14);
      }
    }
  }
}
