/*
---------------------------------------
Breakpoints
---------------------------------------
*/

$se: 320px;
$xd: 375px; // AdobeXD SP Artboards Size
$xs: 430px;
$sm: 640px;
$md: 768px;
$lg: 1024px; // AdobeXD PC Artboards Size
$xl: 1280px;
$max: 1920px;
