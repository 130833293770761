/*
---------------------------------------
Single Class
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// web fontsの読み込み時の FOUT (Flash Of Unstyled Text) 対策(web-font-loaderでclass付与)
.wf-loading {
  opacity: 0;
}

// web fontsの読み込み時の FOUT (Flash Of Unstyled Text) 対策(web-font-loaderでclass付与)
.wf-active {
  opacity: 1;
}

.wf-inactive {
  opacity: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip-path: inset(0 0 99.9% 99.9%);
  border: 0;
}

.sp-only {
  @include sm {
    display: none;
  }
}

.pc-only {
  display: none;

  @include sm {
    display: initial;
  }
}
