/*
---------------------------------------
ytplayer
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin ytplayer-fullscreen-outer {
  position: relative;
  width: 100%;
  height: calc(100vh - $header-height);
  overflow: hidden;

  @include sm {
    height: calc(100vh - $header-height-sm);
  }
}

@mixin ytplayer-fullscreen-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 100vh;
}

@mixin ytplayer-fullscreen-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

@mixin ytplayer-fullscreen-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  min-width: 177.77vh; /* 16 / 9 * 100 = 177.77 */
  height: 56.25vw; /* 9 / 16 * 100 = 56.25 */
  min-height: 100%;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity, visibility 0.6s ease-in-out;
  transform: translate(-50%, calc(-50% - $header-height / 2));
}

@mixin ytplayer-loaded {
  visibility: visible;
  opacity: 1;
}
