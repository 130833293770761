/*
---------------------------------------
Header
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

[id="header"] {
  position: fixed;
  top: 0;
  z-index: 9000;
  width: 100%;
  color: $white;
  background: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease-in-out;

  .header {
    @include sm {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: px2rem(30);
      align-items: center;
      justify-content: center;
      padding-top: px2rem(15);
      padding-bottom: px2rem(15);
      text-align: center;
    }

    // logo
    &__logo {
      display: flex;
      align-items: center;

      &-img {
        width: 23px;

        @include sm {
          width: 23px;
          vertical-align: bottom;
        }
      }
    }

    // inner
    &__inner {
      @include layout-inner;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: px2rem(15);
      padding-bottom: px2rem(15);

      @include sm {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
      }
    }

    // info
    &__info {
      font-size: px2rem(13);
      text-align: center;

      @include sm {
        display: none;
      }

      &-logo {
        width: px2rem(100);
        margin-right: auto;
        margin-left: auto;
      }

      &-address {
        margin-top: px2rem(20);
      }

      &-map {
        @include font-lato;
        margin-top: px2rem(15);
      }

      &-tel {
        @include font-lato;
        margin-top: px2rem(15);
      }

      &-sns {
        display: flex;
        gap: px2rem(40);
        justify-content: center;
        margin-top: px2rem(30);
      }

      &-sns-link {
        width: px2rem(40);
      }

      &-sns-icon {
        width: 100%;
      }
    }

    // sns-heading
    &__sns-heading {
      @include font-lato;
      margin-top: $content-top;
      font-size: px2rem(18);
    }
  }
}
