/*
---------------------------------------
Hamburger Menu Animation
---------------------------------------
*/

@mixin keyframes($animation-name: animation) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation(
  $name: name,
  $duration: 0.5s,
  $timingFunction: ease-in-out,
  $fill-mode: forwards
) {
  animation: $name $duration $timingFunction $fill-mode;
}
