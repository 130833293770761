/*
---------------------------------------
Contact Confirm
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

[id="contact-confirm"] {
  // intro
  .pg-intro {
    // inner
    &__inner {
      @include layout-inner;
    }

    // text
    &__text {
      @include sm {
        text-align: center;
      }
    }
  }

  // confirm-list
  .pg-confirm-list {
    @include sm {
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
    }

    // inner
    &__inner {
      @include layout-inner;
    }

    // item
    &__item {
      display: flex;
      flex-direction: column;
      margin-top: px2rem(30);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  // confirm-item
  .pg-confirm-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: px2rem(30);

    // title
    &__title {
      width: 100%;
      font-weight: $font-bold;
    }

    // input
    &__input {
      width: 100%;
      margin-top: px2rem(5);

      @include sm {
        margin-top: px2rem(10);
      }
    }
  }

  // submit
  .pg-submit-button {
    @include button-rounded;

    // dark-gray
    &--dark-gray {
      @include button-color-dark-gray;
    }

    // light-gray
    &--light-gray {
      @include button-color-light-gray;
    }

    // wrapper
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: px2rem(30);
      align-items: center;
      justify-content: center;
      margin-top: px2rem(60);

      @include sm {
        flex-direction: row;
      }
    }
  }
}
