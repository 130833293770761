/*
---------------------------------------
Values
---------------------------------------
*/

@use "scss/global/functions" as *;
@use "scss/global/variables/breakpoints" as *;

// Inner Width
// ウィンドウ（ブラウザ）画面との余白
$offset-window: px2rem(20);
$offset-window-sm: px2rem(40);
$offset-window-full: px2rem(30);

$inner-width-small: 660px;
$inner-width-base: 1000px;
$inner-width: calc(100% - $offset-window * 2);
$inner-width-sm: calc(100% - $offset-window-sm * 2);
$inner-width-full: calc(100% - $offset-window-full * 2);

// Hamburger Menu Values
$navi-button-width: 22px;
$hamburger-line-height: 2px;
$hamburger-second-line-margin-top-bottom: 6px; //Navi height
$hamburger-animation-translate-y: $hamburger-line-height + $hamburger-second-line-margin-top-bottom;

// Header Height
$header-height: 48px;
$header-height-sm: 55px;

// Card Margin
$card-gap: 2.604%;

// _spaces.scssで使用中の変数

// Margin
$content-top: px2rem(60);
$content-top-sm: px2rem(90);
$item-top: px2rem(12);
$item-top-sm: px2rem(20);
$section-top: px2rem(30);
$section-top-sm: px2rem(60);
$page-first-section-top: px2rem(45);
$page-first-section-bottom: px2rem(60);
$page-first-section-bottom-sm: px2rem(105);

// Padding
$in-content-x: px2rem(30);
$in-content-x-sm: px2rem(66);
$in-content-y: px2rem(60);
$in-content-y-sm: px2rem(80);
$in-item-y: px2rem(30);

// Heading
$heading-26-46-top: px2rem(10);
$heading-26-46-top-sm: px2rem(18);
$heading-18-top: px2rem(6);
$heading-18-top-sm: px2rem(10);
