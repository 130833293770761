/*
---------------------------------------
Pagination Class
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

.c-pagination {
  display: flex;
  gap: px2rem(32);
  justify-content: center;

  &__wrapper {
    margin-top: px2rem(30);
  }

  &__item {
    color: $gray;
  }

  &__active {
    font-weight: $font-bold;
    color: $black;
  }

  &__disabled {
    color: $gray-100;
  }
}
