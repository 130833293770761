/*
---------------------------------------
List Styles
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin this-list-style-base {
  position: relative;
  padding-left: px2rem(15);

  &::before {
    position: absolute;
    left: 0;
    display: inline-block;
  }
}

@mixin circle {
  @include this-list-style-base;

  &::before {
    content: "・";
  }
}
