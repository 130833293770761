/*
---------------------------------------
Colors
---------------------------------------
*/

// Black
$black: #333;

// Gray
$gray: #515151;
$gray-100: #f3f3f3;
$gray-200: #ccc;
$gray-600: #666;

// White
$white: #fff;

// Red
$red: #f05252;

// Blue
$blue: #3f83f8;

// Green
$green: #17a354;
$green-line: #17ca5f;

// Hamburger Line Color
$hamburger-line-color: $gray;

// Calendar Color
$calendar-today: #fef9c3;
$calendar-not-current-month: #fbfbfb;
$calendar-current-month: #fff;
$calendar-color01: #dcfce7;
$calendar-color02: #dbeafe;
$calendar-color03: #fef9c3;
$calendar-color01-alt: #f0fdf4;
$calendar-color02-alt: #eff6ff;
