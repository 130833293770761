/*
---------------------------------------
Instagram Posts
---------------------------------------
*/

.c-instagram-post {
  // item
  &__item {
    display: block;
  }

  // anker
  &__link {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: bottom;
  }

  // image
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
