/*
---------------------------------------
Buttons
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

.c-button {
  // left
  &--left {
    text-align: left;
  }

  // center
  &--center {
    text-align: center;
  }

  // right
  &--right {
    text-align: right;
  }

  // rounded
  &__rounded {
    @include button-rounded;
  }

  // color
  &__color {
    &--dark-gray {
      @include button-color-dark-gray;
    }

    &--white {
      @include button-color-white;
    }
  }

  // animation-arrow-circle
  &__animation-arrow-circle {
    font-size: px2rem(16);

    &:hover {
      opacity: 1;
    }
  }

  // animation-arrow-circle-icon
  &__animation-arrow-circle-icon {
    display: inline-block;
    width: px2rem(40);
    margin-left: px2rem(5);
    vertical-align: middle;
  }

  // arrow
  &__arrow {
    @include button-arrow;
  }
}
