/*
---------------------------------------
Scroll Bar
---------------------------------------
*/

// Scroll Bar Hidden
@mixin scroll-bar-hidden {
  // IE, Edge
  -ms-overflow-style: none;
  // Firefox
  scrollbar-width: none;

  // Chrome, Safari
  &::-webkit-scrollbar {
    display: none;
  }
}
