/*
---------------------------------------
Calenders
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin this-color-sat {
  color: $blue;
}

@mixin this-color-sun {
  color: $red;
}

@mixin this-color-sat {
  color: $blue;
}

@mixin this-style-cell {
  display: flex;
  flex: 1 1 0%;
  min-width: px2rem(160);
  margin-top: -1px;
  margin-left: -1px;
}

// calendar
.c-calendar {
  display: flex;

  // wrapper
  &__wrapper {
    width: 100%;
    overflow-x: auto;
  }

  // tabel
  &__table {
    display: inline-block;
    min-width: 100%;
    overflow: hidden;
  }

  // row
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  // day-of-week
  &__day-of-week {
    @include this-style-cell;
  }

  // week
  &__week {
    font-size: px2rem(14);

    // sat
    &--sat {
      @include this-color-sat;
    }

    // sun
    &--sun {
      @include this-color-sun;
    }
  }

  // day
  &__day {
    @include this-style-cell;
    height: px2rem(160);
    border: 1px solid $gray-100;

    @include sm {
      height: px2rem(192);
    }
  }

  // event-day
  &__event-day {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: px2rem(8);

    // today
    &.today {
      background-color: $calendar-today;
    }

    // not-current-month
    &.not-current-month {
      background-color: $calendar-not-current-month;
    }

    // color01
    &.color01 {
      background-color: $calendar-color01;
    }

    // color02
    &.color02 {
      background-color: $calendar-color02;
    }

    // color03
    &.color03 {
      background-color: $calendar-color03;
    }

    // color01-alt
    &.color01-alt {
      background-color: $calendar-color01-alt;
    }

    // color02-alt
    &.color02-alt {
      background-color: $calendar-color02-alt;
    }
  }

  // number-of-day
  &__number-of-day {
    display: flex;
    align-items: center;
  }

  // day-text
  &__day-text {
    font-size: px2rem(14);

    // day-in-month
    &--day-in-month {
      font-weight: $font-medium;
    }
  }

  // sat
  &__day-sat {
    @include this-color-sat;
  }

  // sun
  &__day-sun {
    @include this-color-sun;
  }

  // event-count
  &__event-count {
    margin-left: px2rem(16);
    font-size: px2rem(12);
    color: $gray;
  }

  // event
  &__event {
    flex: 1 1 0%;
    padding: px2rem(8);
    margin-top: px2rem(8);
    margin-bottom: px2rem(8);
    overflow-y: auto;
  }

  // event-list
  &__event-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-flow: row;
    gap: px2rem(8);
  }

  // event-title
  &__event-title {
    font-size: px2rem(14);
    font-weight: $font-medium;
  }

  // event-description
  &__event-description {
    margin-top: px2rem(2);
    font-size: px2rem(12);
    line-height: $leading-tight;
  }

  // drag-and-drop-wrapper
  &__drag-and-drop-wrapper {
    width: 100%;
    height: 100%;
  }

  // year
  &__year {
    text-align: center;
  }

  // month-wrapper
  &__month-wrapper {
    display: flex;
    gap: px2rem(20);
    justify-content: center;
  }

  // month
  &__month {
    font-size: px2rem(20);
  }
}
