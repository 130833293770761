/*
---------------------------------------
Page Title
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

.c-page-title {
  // heading
  &__heading {
    @include font-futura;

    padding: px2rem(30) 0;
    font-size: px2rem(16);
    font-weight: $font-bold;
    color: $black;
    text-align: center;

    @include sm {
      padding: 0;
    }
  }

  // subpage
  &__subpage {
    @include space-content-top;

    text-align: center;
  }
}
