/*
---------------------------------------
Contact Complete
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

[id="contact-complete"] {
  // intro
  .pg-intro {
    @include space-page-first-section;

    // inner
    &__inner {
      @include layout-inner;
    }

    // heading-text-wrapper
    &__heading-text-wrapper {
      @include sm {
        margin-top: px2rem(90);
      }
    }

    // heading
    &__heading {
      margin-top: px2rem(20);
      font-size: px2rem(18);

      @include sm {
        margin-top: 0;
        font-size: px2rem(40);
        text-align: center;
      }
    }

    // text
    &__text {
      margin-top: px2rem(10);

      @include sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: px2rem(20);
      }
    }

    // text-paragraph
    &__text-paragraph {
      margin-top: px2rem(10);

      @include sm {
        align-self: center;
        margin-top: px2rem(20);
      }
    }

    // button
    &__button {
      margin-top: px2rem(60);
    }
  }
}
