/*
---------------------------------------
Heading Text Section
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

.c-heading-text-section {
  // heading
  &__heading {
    @include heading-26-46;
  }

  // text
  &__text {
    @include text-18-24;
    @include space-heading-26-46-top;
    @include layout-fit-content;
  }
}
