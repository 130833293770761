/*
---------------------------------------
Styles
---------------------------------------
*/

@use "scss/global/mixins/media-queries" as *;
@use "scss/global/mixins/spaces" as *;
@use "scss/global/functions" as *;

// Content with rounded corner
@mixin style-rounded-corner {
  padding: px2rem(30);
  border-radius: px2rem(15);

  @include sm {
    padding: px2rem(45);
  }
}

// Content with a dark gray background color
@mixin style-background-color-dark-gray {
  @include space-in-content;
  color: $white;
  background-color: $gray;
}
