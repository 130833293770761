@charset "UTF-8";
/*
---------------------------------------
Reset
---------------------------------------
*/
/*
---------------------------------------
 Fonts
---------------------------------------
*/
@import "~@fontsource/noto-sans-jp/400.css";
@import "~@fontsource/noto-sans-jp/500.css";
@import "~@fontsource/noto-sans-jp/700.css";
@import "~@fontsource/lato/400.css";
@import "~@fontsource/lato/700.css";
@tailwind base;
/*
---------------------------------------
Base
---------------------------------------
*/
/*
---------------------------------------
Mixins
---------------------------------------
*/
/*
---------------------------------------
Hamburger Menu Animation
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Variables
---------------------------------------
*/
/*
---------------------------------------
Colors
---------------------------------------
*/
/*
---------------------------------------
Breakpoints
---------------------------------------
*/
/*
---------------------------------------
Fonts
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Media Query
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Hover
---------------------------------------
*/
/*
---------------------------------------
Images
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Layouts
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Spaces
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Layouts
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Layouts
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Styles
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Heading
---------------------------------------
*/
/*
---------------------------------------
Text
---------------------------------------
*/
/*
---------------------------------------
Font Family
---------------------------------------
*/
/*
---------------------------------------
Values
---------------------------------------
*/
/*
---------------------------------------
Transition
---------------------------------------
*/
html {
  opacity: 0;
}

.body {
  font-family: "Noto Sans JP", sans-serif;
  min-width: 320px;
  padding-top: 48px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.83;
  color: #333;
  letter-spacing: 0.02em;
}
@media (min-width: 640px) {
  .body {
    padding-top: 55px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity;
  color: #333;
  text-decoration: none;
}
a:hover {
  opacity: 0.6;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

li {
  list-style: none;
}

input,
textarea {
  appearance: none;
}

/*
---------------------------------------
icons
---------------------------------------
*/
@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("files/icomoon.eot?p19nl5");
  src: url("files/icomoon.eot?p19nl5#iefix") format("embedded-opentype"), url("files/icomoon.ttf?p19nl5") format("truetype"), url("files/icomoon.woff?p19nl5") format("woff"), url("files/icomoon.svg?p19nl5#icomoon") format("svg");
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook::before {
  content: "\ea90";
}

.icon-instagram::before {
  content: "\ea92";
}

.icon-twitter::before {
  content: "\ea96";
}

/*
---------------------------------------
Buttons
---------------------------------------
*/
/*
---------------------------------------
Buttons
---------------------------------------
*/
/*
---------------------------------------
Form Items
---------------------------------------
*/
/*
---------------------------------------
List Styles
---------------------------------------
*/
/*
---------------------------------------
Scroll Bar
---------------------------------------
*/
/*
---------------------------------------
ytplayer
---------------------------------------
*/
.c-button--left {
  text-align: left;
}
.c-button--center {
  text-align: center;
}
.c-button--right {
  text-align: right;
}
.c-button__rounded {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity;
  display: inline-block;
  padding: 0 1.25rem;
  font-size: 0.75rem;
  border-radius: 18px;
}
@media (min-width: 640px) {
  .c-button__rounded {
    font-size: 0.875rem;
  }
}
.c-button__rounded::before {
  display: inline-block;
  height: 36px;
  vertical-align: middle;
  content: "";
}
.c-button__color--dark-gray {
  color: #fff;
  background: #515151;
  border-color: #515151;
}
.c-button__color--white {
  color: #515151;
  background: #fff;
}
.c-button__animation-arrow-circle {
  font-size: 1rem;
}
.c-button__animation-arrow-circle:hover {
  opacity: 1;
}
.c-button__animation-arrow-circle-icon {
  display: inline-block;
  width: 2.5rem;
  margin-left: 0.3125rem;
  vertical-align: middle;
}
.c-button__arrow {
  display: inline-block;
  font-size: 1rem;
  color: #333;
}
@media (min-width: 640px) {
  .c-button__arrow {
    font-size: 0.875rem;
  }
}
.c-button__arrow::after {
  display: inline-block;
  vertical-align: middle;
  content: " >";
}
.c-button__arrow:hover {
  text-decoration: underline;
  opacity: 1;
}

/*
---------------------------------------
Calenders
---------------------------------------
*/
.c-calendar {
  display: flex;
}
.c-calendar__wrapper {
  width: 100%;
  overflow-x: auto;
}
.c-calendar__table {
  display: inline-block;
  min-width: 100%;
  overflow: hidden;
}
.c-calendar__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.c-calendar__day-of-week {
  display: flex;
  flex: 1 1 0%;
  min-width: 10rem;
  margin-top: -1px;
  margin-left: -1px;
}
.c-calendar__week {
  font-size: 0.875rem;
}
.c-calendar__week--sat {
  color: #3f83f8;
}
.c-calendar__week--sun {
  color: #f05252;
}
.c-calendar__day {
  display: flex;
  flex: 1 1 0%;
  min-width: 10rem;
  margin-top: -1px;
  margin-left: -1px;
  height: 10rem;
  border: 1px solid #f3f3f3;
}
@media (min-width: 640px) {
  .c-calendar__day {
    height: 12rem;
  }
}
.c-calendar__event-day {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}
.c-calendar__event-day.today {
  background-color: #fef9c3;
}
.c-calendar__event-day.not-current-month {
  background-color: #fbfbfb;
}
.c-calendar__event-day.color01 {
  background-color: #dcfce7;
}
.c-calendar__event-day.color02 {
  background-color: #dbeafe;
}
.c-calendar__event-day.color03 {
  background-color: #fef9c3;
}
.c-calendar__event-day.color01-alt {
  background-color: #f0fdf4;
}
.c-calendar__event-day.color02-alt {
  background-color: #eff6ff;
}
.c-calendar__number-of-day {
  display: flex;
  align-items: center;
}
.c-calendar__day-text {
  font-size: 0.875rem;
}
.c-calendar__day-text--day-in-month {
  font-weight: 500;
}
.c-calendar__day-sat {
  color: #3f83f8;
}
.c-calendar__day-sun {
  color: #f05252;
}
.c-calendar__event-count {
  margin-left: 1rem;
  font-size: 0.75rem;
  color: #515151;
}
.c-calendar__event {
  flex: 1 1 0%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow-y: auto;
}
.c-calendar__event-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-flow: row;
  gap: 0.5rem;
}
.c-calendar__event-title {
  font-size: 0.875rem;
  font-weight: 500;
}
.c-calendar__event-description {
  margin-top: 0.125rem;
  font-size: 0.75rem;
  line-height: 1.4;
}
.c-calendar__drag-and-drop-wrapper {
  width: 100%;
  height: 100%;
}
.c-calendar__year {
  text-align: center;
}
.c-calendar__month-wrapper {
  display: flex;
  gap: 1.25rem;
  justify-content: center;
}
.c-calendar__month {
  font-size: 1.25rem;
}

/*
---------------------------------------
Heading Text Section
---------------------------------------
*/
.c-heading-content {
  font-family: "futura-pt", sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.4;
}
@media (min-width: 640px) {
  .c-heading-content {
    font-size: 1.6875rem;
  }
}

/*
---------------------------------------
Heading Text Section
---------------------------------------
*/
.c-heading-text-section__heading {
  font-size: 1.125rem;
}
.c-heading-text-section__text {
  font-size: 0.875rem;
  margin-top: 0.375rem;
}
@media (min-width: 640px) {
  .c-heading-text-section__text {
    margin-top: 0.625rem;
  }
}

/*
---------------------------------------
Heading Text Section
---------------------------------------
*/
.c-heading-text-section__heading {
  font-size: 1.625rem;
  text-align: center;
}
@media (min-width: 640px) {
  .c-heading-text-section__heading {
    font-size: 2.875rem;
  }
}
.c-heading-text-section__text {
  font-size: 1.125rem;
  margin-top: 0.625rem;
  width: fit-content;
  margin-inline: auto;
}
@media (min-width: 640px) {
  .c-heading-text-section__text {
    font-size: 1.5rem;
  }
}
@media (min-width: 640px) {
  .c-heading-text-section__text {
    margin-top: 1.125rem;
  }
}

/*
---------------------------------------
Instagram Posts
---------------------------------------
*/
.c-instagram-post__item {
  display: block;
}
.c-instagram-post__link {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: bottom;
}
.c-instagram-post__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
---------------------------------------
Page Title
---------------------------------------
*/
.c-page-title__heading {
  font-family: "futura-pt", sans-serif;
  padding: 1.875rem 0;
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  text-align: center;
}
@media (min-width: 640px) {
  .c-page-title__heading {
    padding: 0;
  }
}
.c-page-title__subpage {
  margin-top: 3.75rem;
  text-align: center;
}
@media (min-width: 640px) {
  .c-page-title__subpage {
    margin-top: 5.625rem;
  }
}

/*
---------------------------------------
Pagination Class
---------------------------------------
*/
.c-pagination {
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.c-pagination__wrapper {
  margin-top: 1.875rem;
}
.c-pagination__item {
  color: #515151;
}
.c-pagination__active {
  font-weight: 700;
  color: #333;
}
.c-pagination__disabled {
  color: #f3f3f3;
}

/*
---------------------------------------
swiper
---------------------------------------
*/
.c-swiper.swiper {
  width: 100%;
  height: calc(100vh - 48px);
}
@media (min-width: 640px) {
  .c-swiper.swiper {
    height: calc(100vh - 55px);
  }
}
.c-swiper__slide-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.c-swiper__button.swiper-button-prev, .c-swiper__button.swiper-button-next {
  color: #fff;
}
.c-swiper__button::after {
  display: none;
}
.c-swiper__pagination.swiper-pagination {
  color: #f3f3f3;
}
.c-swiper__pagination .swiper-pagination-bullet {
  opacity: 0.4;
}
.c-swiper__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #f3f3f3;
  opacity: 1;
}

/*
---------------------------------------
Layouts
---------------------------------------
*/
/*
---------------------------------------
Contact Form
---------------------------------------
*/
/*
---------------------------------------
Footer
---------------------------------------
*/
[id=footer] .footer {
  margin-top: 3.75rem;
  padding: 1.875rem 0;
}
@media (min-width: 640px) {
  [id=footer] .footer {
    margin-top: 5.625rem;
  }
}
@media (min-width: 640px) {
  [id=footer] .footer {
    padding: 2.8125rem 2.8125rem 0.9375rem;
    font-size: 0.75rem;
  }
}
[id=footer] .footer__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=footer] .footer__inner {
    width: min(100% - 5rem, 1000px);
  }
}
@media (min-width: 640px) {
  [id=footer] .footer__inner {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media (min-width: 640px) {
  [id=footer] .footer__navi {
    display: flex;
  }
}
[id=footer] .footer__navi--page-name {
  display: inline-block;
  width: 100%;
  padding: 0.625rem 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: #333;
  border-bottom: 0;
}
@media (min-width: 640px) {
  [id=footer] .footer__navi--page-name {
    padding: 0;
  }
}
[id=footer] .footer__navi-item {
  border-bottom: 1px solid rgba(81, 81, 81, 0.2);
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-item {
    margin-right: 1.875rem;
    border-bottom: 0;
  }
  [id=footer] .footer__navi-item:last-child {
    margin-right: 0;
  }
}
[id=footer] .footer__navi-item:first-child {
  border-top: 1px solid rgba(81, 81, 81, 0.2);
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-item:first-child {
    border-top: 0;
  }
}
[id=footer] .footer__navi-parent {
  border-bottom: 1px solid rgba(81, 81, 81, 0.2);
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-parent {
    margin-right: 1.875rem;
    border-bottom: 0;
  }
}
[id=footer] .footer__navi-child {
  padding-bottom: 0.9375rem;
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-child {
    padding-bottom: 0;
  }
}
[id=footer] .footer__navi-child-name {
  display: block;
  padding-left: 0.625rem;
  margin-top: 0.46875rem;
  border-bottom: 0;
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-child-name {
    padding-left: 0;
    margin-top: 0.3125rem;
  }
}
[id=footer] .footer__navi-child-name:first-child {
  margin-top: 0;
}
@media (min-width: 640px) {
  [id=footer] .footer__navi-child-name:first-child {
    margin-top: 0.3125rem;
  }
}
[id=footer] .footer__info {
  margin-top: 2.8125rem;
  text-align: center;
}
@media (min-width: 640px) {
  [id=footer] .footer__info {
    margin-top: 0;
    text-align: left;
  }
}
[id=footer] .footer__info-logo {
  width: 23px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 640px) {
  [id=footer] .footer__info-logo {
    margin-right: 0;
    margin-left: 0;
  }
}
[id=footer] .footer__info-list {
  margin-top: 0.9375rem;
}
[id=footer] .footer__sns {
  margin-top: 0.9375rem;
}
[id=footer] .footer__sns-link {
  display: inline-block;
  width: 1.25rem;
}
[id=footer] .footer__sns-icon {
  width: 100%;
}
[id=footer] .footer__name {
  font-family: "futura-pt", sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 500;
}
[id=footer] .footer__copyright {
  font-family: "Lato", sans-serif;
  margin-top: 2.8125rem;
  font-size: 0.625rem;
  text-align: center;
}
[id=footer] .footer__copyright-text {
  display: inline-block;
}

/*
---------------------------------------
Global Navigation
---------------------------------------
*/
@keyframes naviLineFirstOpen {
  49% {
    opacity: 1;
    transform: translate(0, 6px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(0, 6px);
  }
}
@keyframes naviLineFirstClose {
  0% {
    opacity: 0;
    transform: translate(0, 6px);
  }
  50% {
    opacity: 0;
    transform: translate(0, 6px);
  }
  51% {
    opacity: 1;
  }
  100% {
    transform: translate(0);
  }
}
@keyframes naviLineLastOpen {
  49% {
    opacity: 1;
    transform: translate(0, -6px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(0, -6px);
  }
}
@keyframes naviLineLastClose {
  0% {
    opacity: 0;
    transform: translate(0, -8px);
  }
  50% {
    opacity: 0;
    transform: translate(0, -8px);
  }
  51% {
    opacity: 1;
  }
  100% {
    transform: translate(0);
  }
}
@keyframes naviLineSecondOpen {
  50% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(45deg);
  }
}
@keyframes naviLineSecondClose {
  0% {
    transform: rotateZ(45deg);
  }
  50% {
    transform: rotateZ(0);
  }
}
@keyframes naviLineSecondAfterOpen {
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: rotateZ(0);
  }
  100% {
    opacity: 1;
    transform: rotateZ(-90deg);
  }
}
@keyframes naviLineSecondAfterClose {
  0% {
    opacity: 1;
    transform: rotateZ(-90deg);
  }
  50% {
    opacity: 1;
    transform: rotateZ(0);
  }
  51% {
    opacity: 0;
  }
}
[id=global-navigation] .global-navigation {
  width: 100vw;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  background: #515151;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: height, overflow, padding-bottom, visibility;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation {
    width: auto;
    height: auto;
    visibility: visible;
    background: transparent;
    transition: none;
  }
}
[id=global-navigation] .global-navigation.is-active {
  height: 100vh;
  padding-bottom: 10.9375rem;
  overflow-y: scroll;
  visibility: visible;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: height, overflow, padding-bottom, visibility;
  -webkit-overflow-scrolling: touch;
}
[id=global-navigation] .global-navigation__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 3.75rem 8%;
  overflow: hidden scroll;
  scrollbar-width: 0;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation__list {
    flex-direction: row;
    gap: 1.875rem;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
[id=global-navigation] .global-navigation__list::-webkit-scrollbar {
  display: none;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation__item {
    display: flex;
    align-items: center;
  }
}
[id=global-navigation] .global-navigation__item:first-child {
  border-top: 1px solid #616161;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation__item:first-child {
    border-top: 0;
  }
}
[id=global-navigation] .global-navigation__item--icon {
  display: none;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation__item--icon {
    display: flex;
  }
}
[id=global-navigation] .global-navigation__link {
  display: inline-block;
  width: 100%;
  padding: 0.625rem 0;
  color: #fff;
  border-bottom: 1px solid #616161;
}
@media (min-width: 640px) {
  [id=global-navigation] .global-navigation__link {
    width: auto;
    padding: 0;
    font-size: 0.75rem;
    font-weight: 700;
    color: #333;
    border-bottom: 0;
  }
}
[id=global-navigation] .global-navigation__link--icon {
  align-self: flex-start;
  width: 1.25rem;
}
[id=global-navigation] .global-navigation__link-icon {
  width: 100%;
}

.l-button__hamburger {
  position: relative;
  z-index: 100;
  width: 22px;
  cursor: pointer;
}
@media (min-width: 640px) {
  .l-button__hamburger {
    display: none;
  }
}
.l-button__hamburger--bar {
  width: 100%;
  height: 2px;
  background: #515151;
}
.l-button__hamburger--bar:first-child {
  animation: naviLineFirstClose 0.5s ease-in-out forwards;
}
.l-button__hamburger--bar:nth-child(2) {
  animation: naviLineSecondClose 0.5s ease-in-out forwards;
  position: relative;
  margin: 6px 0;
}
.l-button__hamburger--bar:nth-child(2)::after {
  animation: naviLineSecondAfterClose 0.5s ease-in-out forwards;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background: #515151;
  opacity: 0;
}
.l-button__hamburger--bar:last-child {
  animation: naviLineLastClose 0.5s ease-in-out forwards;
}
.l-button__hamburger.js-closed .l-button__hamburger--bar:first-child {
  animation: none;
}
.l-button__hamburger.js-closed .l-button__hamburger--bar:nth-child(2) {
  animation: none;
}
.l-button__hamburger.js-closed .l-button__hamburger--bar:nth-child(2)::after {
  animation: none;
}
.l-button__hamburger.js-closed .l-button__hamburger--bar:last-child {
  animation: none;
}
.l-button__hamburger.is-active .l-button__hamburger--bar:first-child {
  animation: naviLineFirstOpen 0.5s ease-in-out forwards;
}
.l-button__hamburger.is-active .l-button__hamburger--bar:nth-child(2) {
  animation: naviLineSecondOpen 0.5s ease-in-out forwards;
}
.l-button__hamburger.is-active .l-button__hamburger--bar:nth-child(2)::after {
  animation: naviLineSecondAfterOpen 0.5s ease-in-out forwards;
}
.l-button__hamburger.is-active .l-button__hamburger--bar:last-child {
  animation: naviLineLastOpen 0.5s ease-in-out forwards;
}

/*
---------------------------------------
Header
---------------------------------------
*/
[id=header] {
  position: fixed;
  top: 0;
  z-index: 9000;
  width: 100%;
  color: #fff;
  background: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease-in-out;
}
@media (min-width: 640px) {
  [id=header] .header {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1.875rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    text-align: center;
  }
}
[id=header] .header__logo {
  display: flex;
  align-items: center;
}
[id=header] .header__logo-img {
  width: 23px;
}
@media (min-width: 640px) {
  [id=header] .header__logo-img {
    width: 23px;
    vertical-align: bottom;
  }
}
[id=header] .header__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
@media (min-width: 640px) {
  [id=header] .header__inner {
    width: min(100% - 5rem, 1000px);
  }
}
@media (min-width: 640px) {
  [id=header] .header__inner {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
[id=header] .header__info {
  font-size: 0.8125rem;
  text-align: center;
}
@media (min-width: 640px) {
  [id=header] .header__info {
    display: none;
  }
}
[id=header] .header__info-logo {
  width: 6.25rem;
  margin-right: auto;
  margin-left: auto;
}
[id=header] .header__info-address {
  margin-top: 1.25rem;
}
[id=header] .header__info-map {
  font-family: "Lato", sans-serif;
  margin-top: 0.9375rem;
}
[id=header] .header__info-tel {
  font-family: "Lato", sans-serif;
  margin-top: 0.9375rem;
}
[id=header] .header__info-sns {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  margin-top: 1.875rem;
}
[id=header] .header__info-sns-link {
  width: 2.5rem;
}
[id=header] .header__info-sns-icon {
  width: 100%;
}
[id=header] .header__sns-heading {
  font-family: "Lato", sans-serif;
  margin-top: 3.75rem;
  font-size: 1.125rem;
}

/*
---------------------------------------
Single Class
---------------------------------------
*/
.wf-loading {
  opacity: 0;
}

.wf-active {
  opacity: 1;
}

.wf-inactive {
  opacity: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip-path: inset(0 0 99.9% 99.9%);
  border: 0;
}

@media (min-width: 640px) {
  .sp-only {
    display: none;
  }
}

.pc-only {
  display: none;
}
@media (min-width: 640px) {
  .pc-only {
    display: initial;
  }
}

/*
---------------------------------------
index
---------------------------------------
*/
[id=index] .pg-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10rem;
}
[id=index] .pg-cover__heading {
  font-family: "futura-pt", sans-serif;
  font-size: 0.875rem;
  color: #515151;
  text-align: center;
}
[id=index] .pg-cover__hello {
  font-size: 1.875rem;
  letter-spacing: 0.16em;
}
[id=index] .pg-cover__logo {
  display: block;
  width: clamp(100px, 15vw, 160px);
  margin: 3.75rem auto 0;
}
[id=index] .pg-cover__text {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  max-width: 39.875rem;
  margin-top: 5rem;
  text-align: left;
}
@media (min-width: 640px) {
  [id=index] .pg-cover__text {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 768px) {
  [id=index] .pg-works__inner {
    width: 100%;
  }
}
[id=index] .pg-works__item {
  display: flex;
  flex-direction: column-reverse;
  background-color: #666;
}
@media (min-width: 768px) {
  [id=index] .pg-works__item {
    flex-direction: row;
    height: 100vh;
    min-height: 48rem;
  }
}
[id=index] .pg-works__info {
  font-family: "futura-pt", sans-serif;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  position: relative;
  font-size: 0.75rem;
  font-weight: 500;
}
@media (min-width: 640px) {
  [id=index] .pg-works__info {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 640px) {
  [id=index] .pg-works__info {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  [id=index] .pg-works__info {
    flex: 1 0 20.375rem;
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  [id=index] .pg-works__info {
    flex: 0 0 27.5rem;
  }
}
[id=index] .pg-works__info::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(204, 204, 204, 0.5);
}
[id=index] .pg-works__info-list {
  position: relative;
  z-index: 10;
}
[id=index] .pg-works__work-info-list {
  margin-top: 1.25rem;
}
@media (min-width: 768px) {
  [id=index] .pg-works__work-info-list {
    margin-top: 2.5rem;
  }
}
[id=index] .pg-works__work-info-title {
  display: inline-block;
  font-weight: 500;
}
[id=index] .pg-works__work-info-title::after {
  display: inline-block;
  margin-right: 0.125rem;
  content: ": ";
}
[id=index] .pg-works__work-info-data {
  display: inline-block;
}
[id=index] .pg-works__tag {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=index] .pg-works__tag {
    margin-top: 1.25rem;
  }
}
[id=index] .pg-works__tag-item {
  display: inline-block;
}
[id=index] .pg-works__tag-item::after {
  display: inline-block;
  margin-right: 0.25rem;
  content: ",";
}
[id=index] .pg-works__tag-item:last-child::after {
  display: none;
}
[id=index] .pg-works__client-info {
  margin-top: 1.25rem;
}
@media (min-width: 768px) {
  [id=index] .pg-works__client-info {
    margin-top: 5rem;
  }
}
[id=index] .pg-works__client-info-title {
  display: inline-block;
  font-weight: 500;
}
[id=index] .pg-works__client-info-title::after {
  display: inline-block;
  margin-right: 0.125rem;
  content: ": ";
}
[id=index] .pg-works__client-info-data {
  display: inline-block;
}
[id=index] .pg-works__client-info-data--instagram:hover {
  cursor: pointer;
}
[id=index] .pg-works__client-info-data--instagram::before {
  display: inline;
  content: "@";
}
[id=index] .pg-works__image {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
@media (min-width: 640px) {
  [id=index] .pg-works__image {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 640px) {
  [id=index] .pg-works__image {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  [id=index] .pg-works__image {
    display: grid;
    flex: 1 0 27.625rem;
    place-content: center;
    height: 100%;
  }
}
[id=index] .pg-works__image-img {
  width: 100%;
}
@media (min-width: 768px) {
  [id=index] .pg-works__image-img {
    max-width: 50rem;
    max-height: calc(100vh - 7.5rem);
  }
}
@media (min-width: 1024px) {
  [id=index] .pg-works__image-img {
    max-width: 50rem;
  }
}
[id=index] .pg-works__button {
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  [id=index] .pg-works__button {
    margin-top: 5rem;
  }
}

/*
---------------------------------------
Contact
---------------------------------------
*/
[id=contact] .pg-contact {
  padding-top: 2.8125rem;
  padding-bottom: 3.75rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact {
    padding-bottom: 6.5625rem;
  }
}
[id=contact] .pg-contact__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__inner {
    width: min(100% - 5rem, 1000px);
  }
}
[id=contact] .pg-contact__form {
  margin-top: 1.875rem;
  width: 100%;
  max-width: 35rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form {
    margin-top: 3.75rem;
  }
}
[id=contact] .pg-contact__form-list {
  display: grid;
  justify-items: center;
}
[id=contact] .pg-contact__form-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.875rem;
}
[id=contact] .pg-contact__form-item:first-child {
  margin-top: 0;
}
[id=contact] .pg-contact__form-item-title {
  width: 100%;
  font-weight: 700;
}
[id=contact] .pg-contact__form-item-input {
  width: 100%;
  margin-top: 0.375rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form-item-input {
    margin-top: 0.5rem;
  }
}
[id=contact] .pg-contact__form-submit-button-wrapper {
  margin-top: 1.875rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form-submit-button-wrapper {
    text-align: left;
  }
}
[id=contact] .pg-contact__form-parts {
  padding-top: 0;
}
[id=contact] .pg-contact__form-parts-input {
  display: block;
  width: 100%;
  padding: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.4;
  border: 1px solid #515151;
  border-radius: 0.1875rem;
  width: 100%;
}
[id=contact] .pg-contact__form-parts-input:focus {
  border-color: #515151;
  outline: none;
  box-shadow: 0 0 0 2px rgba(81, 81, 81, 0.5);
}
[id=contact] .pg-contact__form-parts-input.is-invalid {
  border-color: #f05252;
}
[id=contact] .pg-contact__form-parts-textarea {
  display: block;
  width: 100%;
  padding: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.4;
  border: 1px solid #515151;
  border-radius: 0.1875rem;
  width: 100%;
}
[id=contact] .pg-contact__form-parts-textarea:focus {
  border-color: #515151;
  outline: none;
  box-shadow: 0 0 0 2px rgba(81, 81, 81, 0.5);
}
[id=contact] .pg-contact__form-parts-textarea.is-invalid {
  border-color: #f05252;
}
[id=contact] .pg-contact__form-parts-radio-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form-parts-radio-list {
    gap: 0.75rem 1.25rem;
  }
}
[id=contact] .pg-contact__form-parts-radio {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}
[id=contact] .pg-contact__form-parts-radio [type=radio]:focus {
  border-color: #515151;
  outline: none;
  box-shadow: 0 0 0 2px rgba(81, 81, 81, 0.5);
}
[id=contact] .pg-contact__form-parts-radio [type=radio]:checked {
  background-color: #515151;
}
[id=contact] .pg-contact__form-parts-radio.is-invalid input {
  border-color: #f05252;
}
[id=contact] .pg-contact__form-parts-radio.is-invalid label {
  color: #f05252;
}
[id=contact] .pg-contact__form-parts-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form-parts-checkbox-list {
    gap: 0.75rem 1.25rem;
  }
}
[id=contact] .pg-contact__form-parts-checkbox {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}
[id=contact] .pg-contact__form-parts-checkbox [type=checkbox] {
  border-radius: 0.1875rem;
}
[id=contact] .pg-contact__form-parts-checkbox [type=checkbox]:focus {
  border-color: #515151;
  outline: none;
  box-shadow: 0 0 0 2px rgba(81, 81, 81, 0.5);
}
[id=contact] .pg-contact__form-parts-checkbox [type=checkbox]:checked {
  background-color: #515151;
}
[id=contact] .pg-contact__form-parts-checkbox.is-invalid input {
  border-color: #f05252;
}
[id=contact] .pg-contact__form-parts-checkbox.is-invalid label {
  color: #f05252;
}
[id=contact] .pg-contact__form-parts-select {
  display: block;
  width: 100%;
  padding: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.4;
  border: 1px solid #515151;
  border-radius: 0.1875rem;
  width: 100%;
}
[id=contact] .pg-contact__form-parts-select:focus {
  border-color: #515151;
  outline: none;
  box-shadow: 0 0 0 2px rgba(81, 81, 81, 0.5);
}
[id=contact] .pg-contact__form-parts-select.is-invalid {
  border-color: #f05252;
}
[id=contact] .pg-contact__form-parts-required::after {
  display: inline-block;
  color: #f05252;
  content: "※";
}
[id=contact] .pg-contact__form-parts-notes {
  font-size: 0.625rem;
}
[id=contact] .pg-contact__form-parts-helper-text {
  font-size: 0.625rem;
}
[id=contact] .pg-contact__form-parts-error {
  display: block;
  font-size: 0.75rem;
  color: #f05252;
}
[id=contact] .pg-contact__form-parts-button {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity;
  display: inline-block;
  padding: 0 1.25rem;
  font-size: 0.75rem;
  border-radius: 18px;
  color: #fff;
  background: #515151;
  border-color: #515151;
}
@media (min-width: 640px) {
  [id=contact] .pg-contact__form-parts-button {
    font-size: 0.875rem;
  }
}
[id=contact] .pg-contact__form-parts-button::before {
  display: inline-block;
  height: 36px;
  vertical-align: middle;
  content: "";
}
[id=contact] .pg-contact__form-parts-button--back {
  color: #fff;
  background: #515151;
  border-color: #515151;
}

/*
---------------------------------------
Contact Confirm
---------------------------------------
*/
[id=contact-confirm] .pg-intro__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-intro__inner {
    width: min(100% - 5rem, 1000px);
  }
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-intro__text {
    text-align: center;
  }
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-confirm-list {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}
[id=contact-confirm] .pg-confirm-list__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-confirm-list__inner {
    width: min(100% - 5rem, 1000px);
  }
}
[id=contact-confirm] .pg-confirm-list__item {
  display: flex;
  flex-direction: column;
  margin-top: 1.875rem;
}
[id=contact-confirm] .pg-confirm-list__item:first-child {
  margin-top: 0;
}
[id=contact-confirm] .pg-confirm-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.875rem;
}
[id=contact-confirm] .pg-confirm-item__title {
  width: 100%;
  font-weight: 700;
}
[id=contact-confirm] .pg-confirm-item__input {
  width: 100%;
  margin-top: 0.3125rem;
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-confirm-item__input {
    margin-top: 0.625rem;
  }
}
[id=contact-confirm] .pg-submit-button {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity;
  display: inline-block;
  padding: 0 1.25rem;
  font-size: 0.75rem;
  border-radius: 18px;
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-submit-button {
    font-size: 0.875rem;
  }
}
[id=contact-confirm] .pg-submit-button::before {
  display: inline-block;
  height: 36px;
  vertical-align: middle;
  content: "";
}
[id=contact-confirm] .pg-submit-button--dark-gray {
  color: #fff;
  background: #515151;
  border-color: #515151;
}
[id=contact-confirm] .pg-submit-button--light-gray {
  color: #515151;
  background: #f3f3f3;
}
[id=contact-confirm] .pg-submit-button__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  align-items: center;
  justify-content: center;
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=contact-confirm] .pg-submit-button__wrapper {
    flex-direction: row;
  }
}

/*
---------------------------------------
Contact Complete
---------------------------------------
*/
[id=contact-complete] .pg-intro {
  padding-top: 2.8125rem;
  padding-bottom: 3.75rem;
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro {
    padding-bottom: 6.5625rem;
  }
}
[id=contact-complete] .pg-intro__inner {
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro__inner {
    width: min(100% - 5rem, 1000px);
  }
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro__heading-text-wrapper {
    margin-top: 5.625rem;
  }
}
[id=contact-complete] .pg-intro__heading {
  margin-top: 1.25rem;
  font-size: 1.125rem;
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro__heading {
    margin-top: 0;
    font-size: 2.5rem;
    text-align: center;
  }
}
[id=contact-complete] .pg-intro__text {
  margin-top: 0.625rem;
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.25rem;
  }
}
[id=contact-complete] .pg-intro__text-paragraph {
  margin-top: 0.625rem;
}
@media (min-width: 640px) {
  [id=contact-complete] .pg-intro__text-paragraph {
    align-self: center;
    margin-top: 1.25rem;
  }
}
[id=contact-complete] .pg-intro__button {
  margin-top: 3.75rem;
}

/*
---------------------------------------
plants index
---------------------------------------
*/
[id=plants] .c-page-title__heading {
  text-align: left;
}
[id=plants] .pg-plants {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  display: grid;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
[id=plants] .pg-plants__page-title {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  text-align: center;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__page-title {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 1024px) {
  [id=plants] .pg-plants__page-title {
    padding-right: 0;
    padding-left: 4.125rem;
    text-align: left;
  }
}
[id=plants] .pg-plants__howto {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto {
    margin-top: 5.625rem;
  }
}
@media (min-width: 1024px) {
  [id=plants] .pg-plants__howto {
    grid-row-start: 1;
    grid-row-end: 6;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: 100%;
    padding-right: 5.625rem;
    padding-left: 5.625rem;
    margin-top: 0;
  }
}
[id=plants] .pg-plants__howto-item {
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item {
    margin-top: 5.625rem;
  }
}
[id=plants] .pg-plants__howto-item:first-child {
  margin-top: 0;
}
[id=plants] .pg-plants__howto-item-title {
  font-size: 1rem;
}
[id=plants] .pg-plants__howto-item-body {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item-body {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-item-body-content {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item-body-content {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-item-body-content:first-child {
  margin-top: 0;
}
[id=plants] .pg-plants__howto-item-body-heading {
  font-weight: bold;
}
[id=plants] .pg-plants__howto-item-space-top {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item-space-top {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-item-notes {
  font-size: 0.875rem;
  color: #666;
}
[id=plants] .pg-plants__howto-item-flow {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item-flow {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-item-flow--no-space-top {
  margin-top: 0;
}
[id=plants] .pg-plants__howto-item-flow-item {
  list-style-position: inside;
  list-style-type: decimal;
}
[id=plants] .pg-plants__howto-item-list {
  margin-top: 0.75rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-item-list {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-item-list--no-space-top {
  margin-top: 0;
}
[id=plants] .pg-plants__howto-item-list-item {
  list-style-position: inside;
  list-style-type: disc;
}
[id=plants] .pg-plants__howto-navi-list {
  font-family: "futura-pt", sans-serif;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}
@media (min-width: 640px) {
  [id=plants] .pg-plants__howto-navi-list {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 1024px) {
  [id=plants] .pg-plants__howto-navi-list {
    position: sticky;
    top: 3.75rem;
    max-width: 18.75rem;
    padding-right: 0;
    margin-top: 3.75rem;
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-navi-item {
  display: block;
  margin-top: 0.75rem;
}
@media (min-width: 1024px) {
  [id=plants] .pg-plants__howto-navi-item {
    margin-top: 1.25rem;
  }
}
[id=plants] .pg-plants__howto-navi-title {
  font-family: "Lato", sans-serif;
  display: block;
  margin-top: 0.75rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  [id=plants] .pg-plants__howto-navi-title {
    font-size: 0.875rem;
  }
}

/*
---------------------------------------
works index
---------------------------------------
*/
[id=works] .pg-works {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  display: grid;
}
@media (min-width: 640px) {
  [id=works] .pg-works {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
[id=works] .pg-works__page-title {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  text-align: center;
}
@media (min-width: 640px) {
  [id=works] .pg-works__page-title {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 1024px) {
  [id=works] .pg-works__page-title {
    padding-right: 0;
    padding-left: 4.125rem;
    text-align: left;
  }
}
[id=works] .pg-works__list {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=works] .pg-works__list {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 640px) {
  [id=works] .pg-works__list {
    margin-top: 5.625rem;
  }
}
@media (min-width: 1024px) {
  [id=works] .pg-works__list {
    grid-row-start: 1;
    grid-row-end: 6;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: 100%;
    padding-right: 5.625rem;
    padding-left: 5.625rem;
    margin-top: 0;
  }
}
[id=works] .pg-works__list-work {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13.75rem, 1fr));
  gap: 3.75rem;
}
[id=works] .pg-works__item {
  height: fit-content;
}
[id=works] .pg-works__item:hover {
  opacity: 1;
}
[id=works] .pg-works__item:hover .pg-works__item-cover-img {
  transform: scale(1.1);
}
[id=works] .pg-works__category-list {
  font-family: "futura-pt", sans-serif;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  position: sticky;
  top: 3.75rem;
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=works] .pg-works__category-list {
    padding-right: 4.125rem;
    padding-left: 4.125rem;
  }
}
@media (min-width: 1024px) {
  [id=works] .pg-works__category-list {
    max-width: 18.75rem;
    padding-right: 0;
    margin-top: 1.25rem;
  }
}
[id=works] .pg-works__category-item {
  display: block;
  margin-top: 0.75rem;
}
@media (min-width: 1024px) {
  [id=works] .pg-works__category-item {
    margin-top: 1.25rem;
  }
}
[id=works] .pg-works__category-title {
  font-family: "Lato", sans-serif;
  display: block;
  margin-top: 0.75rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  [id=works] .pg-works__category-title {
    font-size: 0.875rem;
  }
}
[id=works] .pg-works__category-tag-item {
  display: inline-block;
  font-size: 0.75rem;
  color: #ccc;
}
[id=works] .pg-works__category-tag-item::after {
  display: inline;
  content: ", ";
}
[id=works] .pg-works__category-tag-item:last-child::after {
  display: none;
}
[id=works] .pg-works__pagination {
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
}
@media (min-width: 640px) {
  [id=works] .pg-works__pagination {
    margin-top: 1.25rem;
  }
}
[id=works] .pg-works__item-cover {
  width: 100%;
  overflow: hidden;
}
[id=works] .pg-works__item-cover-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: transform;
}
[id=works] .pg-works__item-title {
  margin-top: 0.75rem;
  font-weight: 400;
}
[id=works] .pg-works__item-category {
  color: #ccc;
}
[id=works] .pg-works__new {
  font-size: 0.625rem;
  color: #333;
}

/*
---------------------------------------
index
---------------------------------------
*/
[id=works-show] .pg-works-show {
  margin-top: 3.75rem;
  width: min(100% - 2.5rem, 1000px);
  margin-inline: auto;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show {
    margin-top: 5.625rem;
  }
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show {
    width: min(100% - 5rem, 1000px);
  }
}
[id=works-show] .pg-works-show__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  width: 100%;
}
[id=works-show] .pg-works-show__images-wrapper {
  grid-column: 1/3;
  padding: 3.75rem 1.875rem 0;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show__images-wrapper {
    padding-top: 5rem;
  }
}
[id=works-show] .pg-works-show__image {
  margin-top: 3.75rem;
  width: 100%;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show__image {
    margin-top: 5.625rem;
  }
}
@media (min-width: 1024px) {
  [id=works-show] .pg-works-show__image {
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;
  }
}
[id=works-show] .pg-works-show__image:first-child {
  margin-top: 0;
}
[id=works-show] .pg-works-show__image-caption {
  margin-top: 0.3125rem;
  font-size: 0.75rem;
}
[id=works-show] .pg-works-show__body {
  padding-bottom: 1.875rem;
}
@media (min-width: 1024px) {
  [id=works-show] .pg-works-show__body {
    padding-bottom: 2.8125rem;
  }
}
[id=works-show] .pg-works-show__information {
  grid-column: 1/3;
  padding: 3.75rem 1.875rem 0;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show__information {
    padding-top: 5rem;
  }
}
@media (min-width: 768px) {
  [id=works-show] .pg-works-show__information {
    position: sticky;
    top: 0;
    left: 0;
    grid-column: auto;
    height: 100vh;
    overflow-y: scroll;
  }
}
@media (min-width: 1024px) {
  [id=works-show] .pg-works-show__information {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
    gap: 1.25rem;
    align-content: start;
    padding: 5rem 2.8125rem 1.875rem;
  }
}
[id=works-show] .pg-works-show__information-item {
  margin-top: 0.75rem;
}
@media (min-width: 1024px) {
  [id=works-show] .pg-works-show__information-item {
    margin-top: 0;
  }
}
[id=works-show] .pg-works-show__information-item:first-child {
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show__information-item:first-child {
    margin-top: 5.625rem;
  }
}
[id=works-show] .pg-works-show__information-item-heading {
  font-weight: 700;
  color: #333;
}
[id=works-show] .pg-works-show__information-item-text {
  color: #515151;
}
[id=works-show] .pg-works-show__tag {
  display: inline-block;
  font-size: 0.6875rem;
  color: #ccc;
}
[id=works-show] .pg-works-show__tag::after {
  display: inline-block;
  margin-right: 0.25rem;
  content: ",";
}
[id=works-show] .pg-works-show__tag:last-child::after {
  display: none;
}
[id=works-show] .pg-works-show__client-item {
  margin-top: 0.375rem;
  font-size: 0.75rem;
}
[id=works-show] .pg-works-show__client-item-title {
  display: inline-block;
  font-weight: 400;
}
[id=works-show] .pg-works-show__client-item-title::after {
  display: inline-block;
  margin-right: 0.125rem;
  content: ": ";
}
[id=works-show] .pg-works-show__client-item-data {
  display: inline-block;
}
[id=works-show] .pg-works-show__client-item-data--instagram::before {
  display: inline;
  content: "@";
}
[id=works-show] .pg-works-show__roles {
  margin-top: 0.375rem;
  font-size: 0.75rem;
}
[id=works-show] .pg-works-show__roles-role {
  display: inline-block;
  font-weight: 400;
}
[id=works-show] .pg-works-show__roles-role::after {
  display: inline-block;
  margin-right: 0.125rem;
  content: ": ";
}
[id=works-show] .pg-works-show__roles-name {
  display: inline-block;
}
[id=works-show] .pg-works-show__button {
  margin-top: 3.75rem;
}
@media (min-width: 640px) {
  [id=works-show] .pg-works-show__button {
    margin-top: 5.625rem;
  }
}