/*
---------------------------------------
Heading
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/global/mixins/font-families" as *;
@use "scss/global/mixins/media-queries" as *;

// SP:26px, PC:46px
@mixin heading-26-46 {
  font-size: px2rem(26);
  text-align: center;

  @include sm {
    font-size: px2rem(46);
  }
}

// SP:18px, PC:18px
@mixin heading-18 {
  font-size: px2rem(18);
}
