/*
---------------------------------------
works index
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

[id="works"] {
  // works
  .pg-works {
    @include space-in-content-y;

    display: grid;

    // page-title
    &__page-title {
      @include space-in-content-x;

      text-align: center;

      @include lg {
        padding-right: 0;
        padding-left: $in-content-x-sm;
        text-align: left;
      }
    }

    // list
    &__list {
      @include space-in-content-x;
      @include space-content-top;

      @include lg {
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 2;
        grid-column-end: 3;
        width: 100%;
        height: 100%;
        padding-right: px2rem(90);
        padding-left: px2rem(90);
        margin-top: 0;
      }
    }

    // list-work
    &__list-work {
      @include layout-grid($gap: px2rem(60));
    }

    // item
    &__item {
      height: fit-content;

      &:hover {
        opacity: 1;

        .pg-works__item-cover-img {
          transform: scale(1.1);
        }
      }
    }

    // category-list
    &__category-list {
      @include font-futura;
      @include space-in-content-x;

      position: sticky;
      top: $content-top;
      margin-top: $content-top;

      @include lg {
        max-width: px2rem(300);
        padding-right: 0;
        margin-top: $item-top-sm;
      }
    }

    // category-item
    &__category-item {
      display: block;
      margin-top: $item-top;

      @include lg {
        margin-top: $item-top-sm;
      }
    }

    // category-title
    &__category-title {
      @include font-lato;

      display: block;
      margin-top: $item-top;
      font-weight: $font-normal;

      @include lg {
        font-size: px2rem(14);
      }
    }

    // category-tag-item
    &__category-tag-item {
      display: inline-block;
      font-size: px2rem(12);
      color: $gray-200;

      &::after {
        display: inline;
        content: ", ";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    // pagination
    &__pagination {
      @include space-item-top;

      display: flex;
      justify-content: center;
    }

    // item-cover
    &__item-cover {
      width: 100%;
      overflow: hidden;
    }

    // item-cover-img
    &__item-cover-img {
      @include image-object-fit;
      @include transition-works;
    }

    // item-title
    &__item-title {
      margin-top: $item-top;
      font-weight: $font-normal;
    }

    // item-category
    &__item-category {
      color: $gray-200;
    }

    // new
    &__new {
      font-size: px2rem(10);
      color: $black;
    }
  }
}
