/*
---------------------------------------
Hover
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

@mixin hover-opacity {
  opacity: 0.6;
}
