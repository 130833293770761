/*
---------------------------------------
Heading Text Section
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

.c-heading-content {
  @include font-futura;
  font-size: px2rem(20);
  font-style: italic;
  font-weight: 500;
  line-height: $leading-tight;

  @include sm {
    font-size: px2rem(27);
  }
}
