/*
---------------------------------------
Footer
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

[id="footer"] {
  .footer {
    @include space-content-top;
    padding: px2rem(30) 0;

    @include sm {
      padding: px2rem(45) px2rem(45) px2rem(15);
      font-size: px2rem(12);
    }

    &__inner {
      @include layout-inner;

      @include sm {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &__navi {
      @include sm {
        display: flex;
      }

      &--page-name {
        display: inline-block;
        width: 100%;
        padding: px2rem(10) 0;
        font-size: px2rem(12);
        font-weight: $font-medium;
        color: $black;
        border-bottom: 0;

        @include sm {
          padding: 0;
        }
      }
    }

    &__navi-item {
      border-bottom: 1px solid rgba($gray, 0.2);

      @include sm {
        margin-right: px2rem(30);
        border-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }

      &:first-child {
        border-top: 1px solid rgba($gray, 0.2);

        @include sm {
          border-top: 0;
        }
      }
    }

    &__navi-parent {
      border-bottom: 1px solid rgba($gray, 0.2);

      @include sm {
        margin-right: px2rem(30);
        border-bottom: 0;
      }
    }

    &__navi-child {
      padding-bottom: px2rem(15);

      @include sm {
        padding-bottom: 0;
      }
    }

    &__navi-child-name {
      display: block;
      padding-left: px2rem(10);
      margin-top: calc(px2rem(15) / 2);
      border-bottom: 0;

      @include sm {
        padding-left: 0;
        margin-top: px2rem(5);
      }

      &:first-child {
        margin-top: 0;

        @include sm {
          margin-top: px2rem(5);
        }
      }
    }

    &__info {
      margin-top: px2rem(45);
      text-align: center;

      @include sm {
        margin-top: 0;
        text-align: left;
      }
    }

    &__info-logo {
      width: 23px;
      margin-right: auto;
      margin-left: auto;

      @include sm {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &__info-list {
      margin-top: px2rem(15);
    }

    &__sns {
      margin-top: px2rem(15);
    }

    &__sns-link {
      display: inline-block;
      width: px2rem(20);
    }

    &__sns-icon {
      width: 100%;
    }

    // name
    &__name {
      @include font-futura;
      font-size: px2rem(18);
      font-style: italic;
      font-weight: $font-medium;
    }

    &__copyright {
      @include font-lato();
      margin-top: px2rem(45);
      font-size: px2rem(10);
      text-align: center;

      @include sm {
        // margin-top: 0;
      }
    }

    &__copyright-text {
      display: inline-block;
    }
  }
}
