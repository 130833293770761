/*
---------------------------------------
 Fonts
---------------------------------------
*/

// 'Noto Sans JP'
@import "~@fontsource/noto-sans-jp/400.css";
@import "~@fontsource/noto-sans-jp/500.css";
@import "~@fontsource/noto-sans-jp/700.css";

// 'Lato'
@import "~@fontsource/lato/400.css";
@import "~@fontsource/lato/700.css";
