/*
---------------------------------------
Media Query
---------------------------------------
*/

@use "scss/global/variables" as *;
@use "scss/global/functions" as *;

// mediaQueryはmin-widthを使う

@mixin xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}
@mixin md {
  @media (min-width: $md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin max {
  @media (min-width: $max) {
    @content;
  }
}
