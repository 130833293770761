/*
---------------------------------------
Fonts
---------------------------------------
*/

// Font Weight
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
// $font-thin: 100;
// $font-extralight: 200;
// $font-light: 300;
// $font-semibold: 600;
// $font-extrabold: 800;
// $font-black: 900;

// Letter Spacing
$tracking-base: 0.02em;
$tracking-normal: 0;
$tracking-wide: 0.16em;

// Line Height
$leading-base: 1.83;
$leading-none: 1;
$leading-tight: 1.4;
