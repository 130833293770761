/*
---------------------------------------
Contact
---------------------------------------
*/

@use "scss/global/mixins/" as *;
@use "scss/global/variables" as *;
@use "scss/global/functions" as *;
@use "scss/object/utility" as *;

[id="contact"] {
  // contact
  .pg-contact {
    @include space-page-first-section;

    // inner
    &__inner {
      @include layout-inner;
    }

    // form
    &__form {
      @include space-section-top;
      width: 100%;
      max-width: px2rem(560);
    }

    // form-list
    &__form-list {
      display: grid;
      justify-items: center;
    }

    // form-item
    &__form-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: px2rem(30);

      &:first-child {
        margin-top: 0;
      }
    }

    // form-item-title
    &__form-item-title {
      width: 100%;
      font-weight: $font-bold;
    }

    // form-item-input
    &__form-item-input {
      width: 100%;
      margin-top: px2rem(6);

      @include sm {
        margin-top: px2rem(8);
      }
    }

    // form-submit-button-wrapper
    &__form-submit-button-wrapper {
      margin-top: px2rem(30);

      @include sm {
        text-align: left;
      }
    }

    // form-parts
    &__form-parts {
      padding-top: 0;
    }

    // form-parts-input
    &__form-parts-input {
      @include form-item-input;
    }

    // form-parts-textarea
    &__form-parts-textarea {
      @include form-item-textarea;
    }

    // form-parts-radio-list
    &__form-parts-radio-list {
      @include form-item-radio-list;
    }

    // form-parts-radio
    &__form-parts-radio {
      @include form-item-radio;
    }

    // form-parts-checkbox-list
    &__form-parts-checkbox-list {
      @include form-item-checkbox-list;
    }

    // form-parts-checkbox
    &__form-parts-checkbox {
      @include form-item-checkbox;
    }

    // form-parts-select
    &__form-parts-select {
      @include form-item-select;
    }

    // form-parts-required
    &__form-parts-required {
      @include form-item-required;
    }

    // form-parts-notes
    &__form-parts-notes {
      @include form-item-notes;
    }

    // form-parts-helper-text
    &__form-parts-helper-text {
      @include form-item-helper-text;
    }

    // form-parts-error
    &__form-parts-error {
      @include form-item-error;
    }

    // form-parts-button
    &__form-parts-button {
      @include button-rounded;
      @include button-color-dark-gray;

      // back
      &--back {
        @include button-color-dark-gray;
      }
    }
  }
}
